html,
body {
  padding: 0;
  margin: 0;
  --small-font-size: 0.75rem;
  --purple: #673ab7;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.powered-link {
  font-size: var(--small-font-size);
  color: black;
  margin: 0 auto;
}

.vCard-inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;
}

.vCard-inputs > div {
  position: relative;
  background: #f6f6f7 0% 0% no-repeat padding-box;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  color: black;
  display: flex;
}

.vCard-1\/3 {
  width: 40%;
}

.vCard-input {
  display: flex;
  position: relative;
  flex-direction: column;
}

.vCard-input > p {
  margin: 0 !important;
}

.vCard-input > p:nth-child(1) {
  text-align: left;
  font: normal normal normal 12px/14px Calibri;
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 1;
}

.vCard-input > p:nth-child(2) {
  text-align: left;
  font: normal normal bold 1.25rem Calibri;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  word-wrap: break-word;
}

.vCard-input:not(.vCard-1\/3) > p:nth-child(2) {
  width: 85%;
}

.vCard-header > a > img {
  width: 5rem;
}

.vCard-download-button > p {
  margin: 0 auto;
  font: normal normal normal 16px/19px Calibri;
}
.vCard-download-button > span {
  display: flex;
}

.vCard-download-button {
  cursor: pointer;
  background: var(--purple) 0% 0% no-repeat padding-box;
  border: 1px solid var(--purple);
  border-radius: 10px;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  text-align: center;
  transition: 0.2s;
}

.vCard-download-button:hover {
  background: white;
  border: 1px solid black;
  color: black;
}

.vCard-download-button:hover > span > img {
  filter: invert(1);
}

.vCard-container {
  border-radius: 30px;
  padding: 2rem;
  width: 22rem;
  margin: 0 auto;
  background: white;
}

.vCard-clipboard-container {
  top: 0;
  transition: 0.5s;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 1.8rem;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #673ab726;
  border-radius: 5px;
  opacity: 1;
  justify-content: center;
  height: 1.8rem;
  cursor: pointer;
  right: 0;
}

.vCard-clipboard-container:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vCard-clipboard-container > svg {
  width: 50%;
}

.c-div-over-content__div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f6f6f7;
}

.powered-link-container {
  text-align: center;
}

.powered-link > span {
  color: var(--purple);
}
